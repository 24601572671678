import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ボタン - コンポーネント" mdxType="SEO" />
    <PageTitle title="ボタン" enTitle="Button" mdxType="PageTitle" />
    <p>{`ボタンは、ユーザーが実行できるアクションを伝えます。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ameba-spindle.web.app/?path=/docs/button--large"
        }}>{`Storybook`}</a></li>
    </ul>
    <h2 {...{
      "id": "原則",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8E%9F%E5%89%87",
        "aria-label": "原則 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`原則`}</h2>
    <p>{`ボタンには目的の異なる3種類が存在します。`}</p>
    <h3 {...{
      "id": "cta-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#cta-button",
        "aria-label": "cta button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`CTA Button`}</h3>
    <p>{`プラットフォームがユーザーの行動を促す場合に使用するボタンです。`}</p>
    <h3 {...{
      "id": "control-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#control-button",
        "aria-label": "control button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Control Button`}</h3>
    <p>{`ユーザーがラベルに書かれたアクションを実行する時に使用するボタンです。`}</p>
    <h3 {...{
      "id": "link-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#link-button",
        "aria-label": "link button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Link Button`}</h3>
    <p>{`ユーザーに別画面への遷移させる時に使用するボタンです。`}</p>
    <h3 {...{
      "id": "ヒエラルキー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%92%E3%82%A8%E3%83%A9%E3%83%AB%E3%82%AD%E3%83%BC",
        "aria-label": "ヒエラルキー permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ヒエラルキー`}</h3>
    <p>{`ボタンには、その画面におけるアクションの重要度に応じて使用できるスタイルとその個数が決まっています。`}</p>
    <p>{`ボタンのヒエラルキーを重視することで、ユーザーがその画面における主目的を達成するためのアクションを見失わないようにできます。`}</p>
    <p>{`また、ボタンの個数をハンドリングすることで、画面がボタンで埋め尽くされる事態を避けることができます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2bf26b10c0d1c24209b76fb549945123/78958/button-hierarchy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.37500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNUlEQVQoz31STUsDMRDdX+gP8F949SCeFEG9lCpoFb149SRFRYu2W1uvooLogtav1naL2zWbpNk8mSlpt7U4kLwkE17em4kHAHEcI0kSGGNgrYWL7Joi/OniK25D9dUw7+449GjSWjMZkbpEalNGoQWWTtfxEr7i+P4MW9V9PIeNMZIselkVjjB7lrvcxkxhFovFleFZT8asVhsNiykK3YYISZnbH92eYO5wHgvFZca96wOY1HBOaonPsAUhkxEhKfxDmA6s3rzfYa2Ux0ZlB/lyAZv+LlbPcyg/XXGeShRFPUgpx2o+ZlkIwdiOO7h49OEHdR6VoAY/qKEa1FF6KKPRfRuoVBJiopmskF6hxiipuC7NqPXv+PhusmUKpRQr7RszIiSbbNVO+wqD2hBOfqnJBpLlXxG1X//zr2l2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2bf26b10c0d1c24209b76fb549945123/2aaaf/button-hierarchy.webp 160w", "/static/2bf26b10c0d1c24209b76fb549945123/85e47/button-hierarchy.webp 320w", "/static/2bf26b10c0d1c24209b76fb549945123/75198/button-hierarchy.webp 640w", "/static/2bf26b10c0d1c24209b76fb549945123/691bc/button-hierarchy.webp 960w", "/static/2bf26b10c0d1c24209b76fb549945123/223e5/button-hierarchy.webp 1280w", "/static/2bf26b10c0d1c24209b76fb549945123/a407a/button-hierarchy.webp 1320w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2bf26b10c0d1c24209b76fb549945123/69538/button-hierarchy.png 160w", "/static/2bf26b10c0d1c24209b76fb549945123/72799/button-hierarchy.png 320w", "/static/2bf26b10c0d1c24209b76fb549945123/6af66/button-hierarchy.png 640w", "/static/2bf26b10c0d1c24209b76fb549945123/d9199/button-hierarchy.png 960w", "/static/2bf26b10c0d1c24209b76fb549945123/21b4d/button-hierarchy.png 1280w", "/static/2bf26b10c0d1c24209b76fb549945123/78958/button-hierarchy.png 1320w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2bf26b10c0d1c24209b76fb549945123/6af66/button-hierarchy.png",
              "alt": "ボタンのヒエラルキーをピラミッド型に表現し、それぞれの階層ごとのボタン表現を表した図",
              "title": "ボタンのヒエラルキーをピラミッド型に表現し、それぞれの階層ごとのボタン表現を表した図",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`ボタンの階層は大まかに三段階設定しており、それぞれ適用されるボタンのスタイルが異なります。重要度が高くなるごとに配置できるボタンの個数が少なくなります。ボタンのヒエラルキーや個数が図に反して逆転しないように留意してください。`}</p>
    <h2 {...{
      "id": "パターン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3",
        "aria-label": "パターン permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`パターン`}</h2>
    <p>{`ボタンは見た目に応じてUI上での役割が変化します。共通のビジュアルに共通の意味を付与することで、ユーザーのUIの認知に一貫性をもたせ、操作コストを下げることができます。`}</p>
    <h3 {...{
      "id": "contained-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#contained-button",
        "aria-label": "contained button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Contained Button`}</h3>
    <p>{`そのページにおけるユーザーの主目的に対して使用してください。ヒエラルキー最上位のCTA Buttonとして適しています。Containedボタンが画面上に登場する回数は最小限に留めてください。`}</p>
    <p>{`例: 新規登録、今すぐ購入、確定、次へ、等。`}</p>
    <div className="Grid">
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "478px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/644e9670e3655854d50ba153370922b1/50978/button-contained-text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/644e9670e3655854d50ba153370922b1/2aaaf/button-contained-text.webp 160w", "/static/644e9670e3655854d50ba153370922b1/85e47/button-contained-text.webp 320w", "/static/644e9670e3655854d50ba153370922b1/973ba/button-contained-text.webp 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/644e9670e3655854d50ba153370922b1/69538/button-contained-text.png 160w", "/static/644e9670e3655854d50ba153370922b1/72799/button-contained-text.png 320w", "/static/644e9670e3655854d50ba153370922b1/50978/button-contained-text.png 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/644e9670e3655854d50ba153370922b1/50978/button-contained-text.png",
                "alt": "テキストラベルのみのContained Buttonのサンプル",
                "title": "テキストラベルのみのContained Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c824470bf0ca5dce8be7f4d3586a6ed1/e85cb/button-contained-text-with-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c824470bf0ca5dce8be7f4d3586a6ed1/2aaaf/button-contained-text-with-icon.webp 160w", "/static/c824470bf0ca5dce8be7f4d3586a6ed1/85e47/button-contained-text-with-icon.webp 320w", "/static/c824470bf0ca5dce8be7f4d3586a6ed1/5bde3/button-contained-text-with-icon.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/c824470bf0ca5dce8be7f4d3586a6ed1/69538/button-contained-text-with-icon.png 160w", "/static/c824470bf0ca5dce8be7f4d3586a6ed1/72799/button-contained-text-with-icon.png 320w", "/static/c824470bf0ca5dce8be7f4d3586a6ed1/e85cb/button-contained-text-with-icon.png 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c824470bf0ca5dce8be7f4d3586a6ed1/e85cb/button-contained-text-with-icon.png",
                "alt": "アイコン付きテキストラベルのContained Buttonのサンプル",
                "title": "アイコン付きテキストラベルのContained Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    </div>
    <h3 {...{
      "id": "outlined-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outlined-button",
        "aria-label": "outlined button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Outlined Button`}</h3>
    <p>{`Containedボタンに対して相対的に見て次点で強力なアクションに対して使用できます。また、このボタンは、押下することでステータスを切り替えるボタンにも使用できます。`}</p>
    <p>{`例: フォロー、(新規登録に対する)ログイン、等。`}</p>
    <div className="Grid">
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "478px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/886f4735c1c6e4953df337ca97c82631/50978/button-outlined-text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/886f4735c1c6e4953df337ca97c82631/2aaaf/button-outlined-text.webp 160w", "/static/886f4735c1c6e4953df337ca97c82631/85e47/button-outlined-text.webp 320w", "/static/886f4735c1c6e4953df337ca97c82631/973ba/button-outlined-text.webp 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/886f4735c1c6e4953df337ca97c82631/69538/button-outlined-text.png 160w", "/static/886f4735c1c6e4953df337ca97c82631/72799/button-outlined-text.png 320w", "/static/886f4735c1c6e4953df337ca97c82631/50978/button-outlined-text.png 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/886f4735c1c6e4953df337ca97c82631/50978/button-outlined-text.png",
                "alt": "テキストラベルのみのOutlined Buttonのサンプル",
                "title": "テキストラベルのみのOutlined Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d896484f1d032fa5ada966747c684e96/e85cb/button-outlined-text-with-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d896484f1d032fa5ada966747c684e96/2aaaf/button-outlined-text-with-icon.webp 160w", "/static/d896484f1d032fa5ada966747c684e96/85e47/button-outlined-text-with-icon.webp 320w", "/static/d896484f1d032fa5ada966747c684e96/5bde3/button-outlined-text-with-icon.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d896484f1d032fa5ada966747c684e96/69538/button-outlined-text-with-icon.png 160w", "/static/d896484f1d032fa5ada966747c684e96/72799/button-outlined-text-with-icon.png 320w", "/static/d896484f1d032fa5ada966747c684e96/e85cb/button-outlined-text-with-icon.png 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d896484f1d032fa5ada966747c684e96/e85cb/button-outlined-text-with-icon.png",
                "alt": "アイコン付きテキストラベルのOutlined Buttonのサンプル",
                "title": "アイコン付きテキストラベルのOutlined Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    </div>
    <h3 {...{
      "id": "neutral-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#neutral-button",
        "aria-label": "neutral button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Neutral Button`}</h3>
    <p>{`その画面においてユーザーの主目的とは異なるボタンに対して使うことができます。このボタンは隣接して何個でもならべることができます。`}</p>
    <p>{`例: 詳細を見る、トップへ戻る、（非破壊的な）削除等。`}</p>
    <div className="Grid">
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "478px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d581c913e4a2cc06fd6d503d3699c4c0/50978/button-neutral-text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d581c913e4a2cc06fd6d503d3699c4c0/2aaaf/button-neutral-text.webp 160w", "/static/d581c913e4a2cc06fd6d503d3699c4c0/85e47/button-neutral-text.webp 320w", "/static/d581c913e4a2cc06fd6d503d3699c4c0/973ba/button-neutral-text.webp 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d581c913e4a2cc06fd6d503d3699c4c0/69538/button-neutral-text.png 160w", "/static/d581c913e4a2cc06fd6d503d3699c4c0/72799/button-neutral-text.png 320w", "/static/d581c913e4a2cc06fd6d503d3699c4c0/50978/button-neutral-text.png 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d581c913e4a2cc06fd6d503d3699c4c0/50978/button-neutral-text.png",
                "alt": "テキストラベルのみのNeutral Buttonのサンプル",
                "title": "テキストラベルのみのNeutral Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/832440f941fbc958a1242615835d8d53/e85cb/button-neutral-text-with-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/832440f941fbc958a1242615835d8d53/2aaaf/button-neutral-text-with-icon.webp 160w", "/static/832440f941fbc958a1242615835d8d53/85e47/button-neutral-text-with-icon.webp 320w", "/static/832440f941fbc958a1242615835d8d53/5bde3/button-neutral-text-with-icon.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/832440f941fbc958a1242615835d8d53/69538/button-neutral-text-with-icon.png 160w", "/static/832440f941fbc958a1242615835d8d53/72799/button-neutral-text-with-icon.png 320w", "/static/832440f941fbc958a1242615835d8d53/e85cb/button-neutral-text-with-icon.png 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/832440f941fbc958a1242615835d8d53/e85cb/button-neutral-text-with-icon.png",
                "alt": "アイコン付きテキストラベルのNeutral Buttonのサンプル",
                "title": "アイコン付きテキストラベルのNeutral Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    </div>
    <h3 {...{
      "id": "lighted-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lighted-button",
        "aria-label": "lighted button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Lighted Button`}</h3>
    <p>{`Neutral Buttonに対して、ステータスが変化したことを表すために使用するボタンです。`}</p>
    <p>{`Contained Buttonがタップを促す強調ボタンであることに対して、Lighted Buttonは状態がアクティブであることを表現する強調ボタンになります。単独で使用することはありません。`}</p>
    <div className="Grid">
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "478px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/149ff114c5678ac5c1acd6b0e7f230f1/50978/button-lighted-text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/149ff114c5678ac5c1acd6b0e7f230f1/2aaaf/button-lighted-text.webp 160w", "/static/149ff114c5678ac5c1acd6b0e7f230f1/85e47/button-lighted-text.webp 320w", "/static/149ff114c5678ac5c1acd6b0e7f230f1/973ba/button-lighted-text.webp 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/149ff114c5678ac5c1acd6b0e7f230f1/69538/button-lighted-text.png 160w", "/static/149ff114c5678ac5c1acd6b0e7f230f1/72799/button-lighted-text.png 320w", "/static/149ff114c5678ac5c1acd6b0e7f230f1/50978/button-lighted-text.png 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/149ff114c5678ac5c1acd6b0e7f230f1/50978/button-lighted-text.png",
                "alt": "テキストラベルのみのLighted Buttonのサンプル",
                "title": "テキストラベルのみのLighted Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/681c5b915d7900468109398c5773b02e/e85cb/button-lighted-text-with-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/681c5b915d7900468109398c5773b02e/2aaaf/button-lighted-text-with-icon.webp 160w", "/static/681c5b915d7900468109398c5773b02e/85e47/button-lighted-text-with-icon.webp 320w", "/static/681c5b915d7900468109398c5773b02e/5bde3/button-lighted-text-with-icon.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/681c5b915d7900468109398c5773b02e/69538/button-lighted-text-with-icon.png 160w", "/static/681c5b915d7900468109398c5773b02e/72799/button-lighted-text-with-icon.png 320w", "/static/681c5b915d7900468109398c5773b02e/e85cb/button-lighted-text-with-icon.png 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/681c5b915d7900468109398c5773b02e/e85cb/button-lighted-text-with-icon.png",
                "alt": "アイコン付きテキストラベルのLighted Buttonのサンプル",
                "title": "アイコン付きテキストラベルのLighted Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    </div>
    <h3 {...{
      "id": "danger-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#danger-button",
        "aria-label": "danger button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Danger Button`}</h3>
    <p>{`取り返しのつかない破壊的な動作への最終確認に対して使用します。`}</p>
    <p>{`例: 投稿削除、退会、等。`}</p>
    <div className="Grid">
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "478px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1b26e3b871becf8710b7b587b91f380a/50978/button-danger-text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1b26e3b871becf8710b7b587b91f380a/2aaaf/button-danger-text.webp 160w", "/static/1b26e3b871becf8710b7b587b91f380a/85e47/button-danger-text.webp 320w", "/static/1b26e3b871becf8710b7b587b91f380a/973ba/button-danger-text.webp 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1b26e3b871becf8710b7b587b91f380a/69538/button-danger-text.png 160w", "/static/1b26e3b871becf8710b7b587b91f380a/72799/button-danger-text.png 320w", "/static/1b26e3b871becf8710b7b587b91f380a/50978/button-danger-text.png 478w"],
                "sizes": "(max-width: 478px) 100vw, 478px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1b26e3b871becf8710b7b587b91f380a/50978/button-danger-text.png",
                "alt": "テキストラベルのみのDanger Buttonのサンプル",
                "title": "テキストラベルのみのDanger Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
      <p>{`
  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "480px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/dbbe7fb3bcbae620d72f28bbeabe7511/e85cb/button-danger-text-with-icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/dbbe7fb3bcbae620d72f28bbeabe7511/2aaaf/button-danger-text-with-icon.webp 160w", "/static/dbbe7fb3bcbae620d72f28bbeabe7511/85e47/button-danger-text-with-icon.webp 320w", "/static/dbbe7fb3bcbae620d72f28bbeabe7511/5bde3/button-danger-text-with-icon.webp 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/dbbe7fb3bcbae620d72f28bbeabe7511/69538/button-danger-text-with-icon.png 160w", "/static/dbbe7fb3bcbae620d72f28bbeabe7511/72799/button-danger-text-with-icon.png 320w", "/static/dbbe7fb3bcbae620d72f28bbeabe7511/e85cb/button-danger-text-with-icon.png 480w"],
                "sizes": "(max-width: 480px) 100vw, 480px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/dbbe7fb3bcbae620d72f28bbeabe7511/e85cb/button-danger-text-with-icon.png",
                "alt": "アイコン付きテキストラベルのDanger Buttonのサンプル",
                "title": "アイコン付きテキストラベルのDanger Buttonのサンプル",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    </div>
    <h3 {...{
      "id": "subtle-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#subtle-button",
        "aria-label": "subtle button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Subtle Button`}</h3>
    <p>{`Contained,Outlined,Neutralボタンと同時に使用します。
ポップアップダイアログを閉じる時など、それほどユーザーの主目的ではないアクションに対して使用します。`}</p>
    <p>{`例: キャンセル、とじる、等。`}</p>
    <p>{`TODO: Subtle Buttonボタンのイメージを置く。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "478px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/23b75505c91fea14294c8612f932aab3/50978/button-subtle.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/23b75505c91fea14294c8612f932aab3/2aaaf/button-subtle.webp 160w", "/static/23b75505c91fea14294c8612f932aab3/85e47/button-subtle.webp 320w", "/static/23b75505c91fea14294c8612f932aab3/973ba/button-subtle.webp 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/23b75505c91fea14294c8612f932aab3/69538/button-subtle.png 160w", "/static/23b75505c91fea14294c8612f932aab3/72799/button-subtle.png 320w", "/static/23b75505c91fea14294c8612f932aab3/50978/button-subtle.png 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/23b75505c91fea14294c8612f932aab3/50978/button-subtle.png",
              "alt": "Subtle Buttonのサンプル",
              "title": "Subtle Buttonのサンプル",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "text-link-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#text-link-button",
        "aria-label": "text link button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Text Link Button`}</h3>
    <p>{`主に別ページへ遷移させる時の導線ボタンとして使用します。文中リンクなど、リンクテキストには原則下線を用います。
例: 利用規約、もっとみる、等。`}</p>
    <p>{`ただし、慣習的に下線がなくともリンクであることが分かる場合や、矢印アイコンなど移動を表す補足情報がある場合は下線が無くても良いものとします。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "305px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d8a8d5a7943d284a8aef5df21d428589/a3e09/FXsOW-3262%3A573.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d8a8d5a7943d284a8aef5df21d428589/2aaaf/FXsOW-3262%3A573.webp 160w", "/static/d8a8d5a7943d284a8aef5df21d428589/61df3/FXsOW-3262%3A573.webp 305w"],
              "sizes": "(max-width: 305px) 100vw, 305px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d8a8d5a7943d284a8aef5df21d428589/69538/FXsOW-3262%3A573.png 160w", "/static/d8a8d5a7943d284a8aef5df21d428589/a3e09/FXsOW-3262%3A573.png 305w"],
              "sizes": "(max-width: 305px) 100vw, 305px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d8a8d5a7943d284a8aef5df21d428589/a3e09/FXsOW-3262%3A573.png",
              "alt": "Text Link Buttonのサンプル",
              "title": "Text Link Buttonのサンプル",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`色だけでリンクと伝えると全てのユーザーにリンクであると伝わらない可能性があります。
そのため、文中リンクなど、リンクテキストには原則下線を用います。`}<br />{`
参考：`}<a parentName="p" {...{
        "href": "https://openameba.github.io/a11y-guidelines/1/4/1/"
      }}>{`1.4.1 色だけで伝えない - Ameba Accessibility Guidelines`}</a></p>
    <h3 {...{
      "id": "subtle-link-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#subtle-link-button",
        "aria-label": "subtle link button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Subtle Link Button`}</h3>
    <p>{`Subtleボタンと似た見た目をしていますが、リンクボタンとして使います。
画面が緑色のリンクボタンで埋まらないようにバランスを取るために、重要でないリンクに対して使います。
文中リンクとしては使えません。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/18a2eb38136dcfac72190c97ed751fbf/dba9a/button-subtle-link.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/18a2eb38136dcfac72190c97ed751fbf/2aaaf/button-subtle-link.webp 160w", "/static/18a2eb38136dcfac72190c97ed751fbf/85e47/button-subtle-link.webp 320w", "/static/18a2eb38136dcfac72190c97ed751fbf/75198/button-subtle-link.webp 640w", "/static/18a2eb38136dcfac72190c97ed751fbf/45b3b/button-subtle-link.webp 652w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/18a2eb38136dcfac72190c97ed751fbf/69538/button-subtle-link.png 160w", "/static/18a2eb38136dcfac72190c97ed751fbf/72799/button-subtle-link.png 320w", "/static/18a2eb38136dcfac72190c97ed751fbf/6af66/button-subtle-link.png 640w", "/static/18a2eb38136dcfac72190c97ed751fbf/dba9a/button-subtle-link.png 652w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/18a2eb38136dcfac72190c97ed751fbf/6af66/button-subtle-link.png",
              "alt": "Subtle Link Buttonのサンプル",
              "title": "Subtle Link Buttonのサンプル",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "状態の切り替わるボタン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%8A%B6%E6%85%8B%E3%81%AE%E5%88%87%E3%82%8A%E6%9B%BF%E3%82%8F%E3%82%8B%E3%83%9C%E3%82%BF%E3%83%B3",
        "aria-label": "状態の切り替わるボタン permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`状態の切り替わるボタン`}</h3>
    <p>{`ボタンの中には、フォローボタンのように、ユーザーの入力によってボタンのステータスを切り替えるものが存在します。
利用可能な組み合わせは下記の通りで、初期状態は必ずNormalの状態にしておく必要があります。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/780ee3c34a0c43f025c539d8f58ec7b6/f3c12/button-stateful.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABfElEQVQ4y5VT227bMAzNP27oa7EvWx760BUY9iED1scW7VNuTbLasizJlmRROgXlePMSJ8gIENQ5IGnePEspgYXtoMf4Wp5lNgaDHOP/4WcDqJVCWQqEEDIOFKCcRm0VlNXoqMt8jBFaG8i6hjHmtMLh8V4UWK038N5n7ILHRm6xFCusqw1sZzNPMWK322OxXGXLH5hMOJ7H2KFp2pOgnm+mWx4IDhp07CBVDSL6J5Ctbe35hCzbQxvamIy53Zv5F3ye3+LT11s871/62caAtXrDc/GKhVyBEk233FqHUog/7Rln8PD4A3c/v+H+13eIpjoshaC9hmgFaqcQUzxzNhT+toBpOctPzdC9PCGUBYi1KkGygnvfwUuBTitQY0A8z1oieg9irhLnDzuyAweUBcLvfU7IOHmP1HVIIfSWMb+9R3RuOmEe9u4NYbsBSYFoLWLb9JXWMieORmcuHirLKqucfHIp7Ji1MUhE/V0eTuiSHt/w7NI/eq2Ml/IBhEpGSZhxw4YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/780ee3c34a0c43f025c539d8f58ec7b6/2aaaf/button-stateful.webp 160w", "/static/780ee3c34a0c43f025c539d8f58ec7b6/85e47/button-stateful.webp 320w", "/static/780ee3c34a0c43f025c539d8f58ec7b6/75198/button-stateful.webp 640w", "/static/780ee3c34a0c43f025c539d8f58ec7b6/7a462/button-stateful.webp 764w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/780ee3c34a0c43f025c539d8f58ec7b6/69538/button-stateful.png 160w", "/static/780ee3c34a0c43f025c539d8f58ec7b6/72799/button-stateful.png 320w", "/static/780ee3c34a0c43f025c539d8f58ec7b6/6af66/button-stateful.png 640w", "/static/780ee3c34a0c43f025c539d8f58ec7b6/f3c12/button-stateful.png 764w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/780ee3c34a0c43f025c539d8f58ec7b6/6af66/button-stateful.png",
              "alt": "フォロー前、フォロー後の状態を表したボタンの例",
              "title": "フォロー前、フォロー後の状態を表したボタンの例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`注意: NeutralとLightedなボタンの切り替えで利用するときは、テキストの内容やアイコンを変化させることで、差分を設けましょう。背景と文字色のコントラスト比が前後で余り変わらないため、色以外で知覚できる差分が必要なためです。`}</p>
    <h3 {...{
      "id": "各パターンの一覧",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%90%84%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3%E3%81%AE%E4%B8%80%E8%A6%A7",
        "aria-label": "各パターンの一覧 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`各パターンの一覧`}</h3>
    <p>{`重要なアクションほど目立たせ、リンクをリンクらしくあしらう事で差別化を行っています。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bd20144168e5b9bd40720bbf34f4a958/d774a/button-variation-chart.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/bd20144168e5b9bd40720bbf34f4a958/2aaaf/button-variation-chart.webp 160w", "/static/bd20144168e5b9bd40720bbf34f4a958/85e47/button-variation-chart.webp 320w", "/static/bd20144168e5b9bd40720bbf34f4a958/75198/button-variation-chart.webp 640w", "/static/bd20144168e5b9bd40720bbf34f4a958/691bc/button-variation-chart.webp 960w", "/static/bd20144168e5b9bd40720bbf34f4a958/223e5/button-variation-chart.webp 1280w", "/static/bd20144168e5b9bd40720bbf34f4a958/bfb77/button-variation-chart.webp 4138w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/bd20144168e5b9bd40720bbf34f4a958/69538/button-variation-chart.png 160w", "/static/bd20144168e5b9bd40720bbf34f4a958/72799/button-variation-chart.png 320w", "/static/bd20144168e5b9bd40720bbf34f4a958/6af66/button-variation-chart.png 640w", "/static/bd20144168e5b9bd40720bbf34f4a958/d9199/button-variation-chart.png 960w", "/static/bd20144168e5b9bd40720bbf34f4a958/21b4d/button-variation-chart.png 1280w", "/static/bd20144168e5b9bd40720bbf34f4a958/d774a/button-variation-chart.png 4138w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bd20144168e5b9bd40720bbf34f4a958/6af66/button-variation-chart.png",
              "alt": "ボタンのパターンごとの条件の一覧",
              "title": "ボタンのパターンごとの条件の一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "解剖",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%A7%A3%E5%89%96",
        "aria-label": "解剖 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`解剖`}</h2>
    <p>{`ボタンの形態にバリエーションが存在します。`}</p>
    <p>{`ボタンの形態はレイアウトによって使い分けつつ、画面におけるボタンの強弱をサイズによって表すことができます。それぞれの形態のボタンにおいてレイアウトに指定が存在します。`}</p>
    <h3 {...{
      "id": "large-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#large-button",
        "aria-label": "large button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Large Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/45f65f339b5133f6467a436572c0d74f/3e992/button-large-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/45f65f339b5133f6467a436572c0d74f/2aaaf/button-large-anatomy.webp 160w", "/static/45f65f339b5133f6467a436572c0d74f/85e47/button-large-anatomy.webp 320w", "/static/45f65f339b5133f6467a436572c0d74f/75198/button-large-anatomy.webp 640w", "/static/45f65f339b5133f6467a436572c0d74f/691bc/button-large-anatomy.webp 960w", "/static/45f65f339b5133f6467a436572c0d74f/223e5/button-large-anatomy.webp 1280w", "/static/45f65f339b5133f6467a436572c0d74f/b58ff/button-large-anatomy.webp 1902w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/45f65f339b5133f6467a436572c0d74f/69538/button-large-anatomy.png 160w", "/static/45f65f339b5133f6467a436572c0d74f/72799/button-large-anatomy.png 320w", "/static/45f65f339b5133f6467a436572c0d74f/6af66/button-large-anatomy.png 640w", "/static/45f65f339b5133f6467a436572c0d74f/d9199/button-large-anatomy.png 960w", "/static/45f65f339b5133f6467a436572c0d74f/21b4d/button-large-anatomy.png 1280w", "/static/45f65f339b5133f6467a436572c0d74f/3e992/button-large-anatomy.png 1902w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/45f65f339b5133f6467a436572c0d74f/6af66/button-large-anatomy.png",
              "alt": "Large Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Large Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Large Buttonは、Amebaで用いる事のできる最大のボタンで、必然的にその画面における最重要なボタンとなります。
その画面におけるユーザーの主目的足りうるアクションに対して使用してください。`}</p>
    <h3 {...{
      "id": "medium-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#medium-button",
        "aria-label": "medium button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Medium Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/46743e941fc47c475b3d29989c5d5281/d5b59/button-medium-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.625%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/46743e941fc47c475b3d29989c5d5281/2aaaf/button-medium-anatomy.webp 160w", "/static/46743e941fc47c475b3d29989c5d5281/85e47/button-medium-anatomy.webp 320w", "/static/46743e941fc47c475b3d29989c5d5281/75198/button-medium-anatomy.webp 640w", "/static/46743e941fc47c475b3d29989c5d5281/691bc/button-medium-anatomy.webp 960w", "/static/46743e941fc47c475b3d29989c5d5281/223e5/button-medium-anatomy.webp 1280w", "/static/46743e941fc47c475b3d29989c5d5281/99759/button-medium-anatomy.webp 1370w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/46743e941fc47c475b3d29989c5d5281/69538/button-medium-anatomy.png 160w", "/static/46743e941fc47c475b3d29989c5d5281/72799/button-medium-anatomy.png 320w", "/static/46743e941fc47c475b3d29989c5d5281/6af66/button-medium-anatomy.png 640w", "/static/46743e941fc47c475b3d29989c5d5281/d9199/button-medium-anatomy.png 960w", "/static/46743e941fc47c475b3d29989c5d5281/21b4d/button-medium-anatomy.png 1280w", "/static/46743e941fc47c475b3d29989c5d5281/d5b59/button-medium-anatomy.png 1370w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/46743e941fc47c475b3d29989c5d5281/6af66/button-medium-anatomy.png",
              "alt": "Medium Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Medium Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Medium Buttonは、カードやダイアログなどの、内包されたコンポーネントにおいては最大となるボタンです。Large Buttonに次いで大きなボタンとなり、任意のコンポーネントの中ではユーザーの主目的を示唆するに足りうる力を持ちます。`}</p>
    <h3 {...{
      "id": "small-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#small-button",
        "aria-label": "small button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Small Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/744a2b85832d34d4370c5794aaf2a979/09e48/button-small-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "113.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/744a2b85832d34d4370c5794aaf2a979/2aaaf/button-small-anatomy.webp 160w", "/static/744a2b85832d34d4370c5794aaf2a979/85e47/button-small-anatomy.webp 320w", "/static/744a2b85832d34d4370c5794aaf2a979/75198/button-small-anatomy.webp 640w", "/static/744a2b85832d34d4370c5794aaf2a979/691bc/button-small-anatomy.webp 960w", "/static/744a2b85832d34d4370c5794aaf2a979/1943e/button-small-anatomy.webp 974w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/744a2b85832d34d4370c5794aaf2a979/69538/button-small-anatomy.png 160w", "/static/744a2b85832d34d4370c5794aaf2a979/72799/button-small-anatomy.png 320w", "/static/744a2b85832d34d4370c5794aaf2a979/6af66/button-small-anatomy.png 640w", "/static/744a2b85832d34d4370c5794aaf2a979/d9199/button-small-anatomy.png 960w", "/static/744a2b85832d34d4370c5794aaf2a979/09e48/button-small-anatomy.png 974w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/744a2b85832d34d4370c5794aaf2a979/6af66/button-small-anatomy.png",
              "alt": "Small Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Small Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Small Buttonは、リストなどの幅や高さに制限が発生する場面で活躍するコンパクトなボタンです。リストに対しての実行アクションから消極的なアクションまで幅広く表現できます。`}</p>
    <p>{`対して、画面におけるユーザーの主目的を達成するメインボタンとしては弱く、適しているとは言えません。`}</p>
    <h3 {...{
      "id": "bottom-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bottom-button",
        "aria-label": "bottom button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Bottom Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ac095bbf139eb3f914be08314eae597a/f98ee/button-bottom-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.37499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ac095bbf139eb3f914be08314eae597a/2aaaf/button-bottom-anatomy.webp 160w", "/static/ac095bbf139eb3f914be08314eae597a/85e47/button-bottom-anatomy.webp 320w", "/static/ac095bbf139eb3f914be08314eae597a/75198/button-bottom-anatomy.webp 640w", "/static/ac095bbf139eb3f914be08314eae597a/691bc/button-bottom-anatomy.webp 960w", "/static/ac095bbf139eb3f914be08314eae597a/223e5/button-bottom-anatomy.webp 1280w", "/static/ac095bbf139eb3f914be08314eae597a/91d14/button-bottom-anatomy.webp 1808w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ac095bbf139eb3f914be08314eae597a/69538/button-bottom-anatomy.png 160w", "/static/ac095bbf139eb3f914be08314eae597a/72799/button-bottom-anatomy.png 320w", "/static/ac095bbf139eb3f914be08314eae597a/6af66/button-bottom-anatomy.png 640w", "/static/ac095bbf139eb3f914be08314eae597a/d9199/button-bottom-anatomy.png 960w", "/static/ac095bbf139eb3f914be08314eae597a/21b4d/button-bottom-anatomy.png 1280w", "/static/ac095bbf139eb3f914be08314eae597a/f98ee/button-bottom-anatomy.png 1808w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ac095bbf139eb3f914be08314eae597a/6af66/button-bottom-anatomy.png",
              "alt": "Bottom Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Bottom Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Bottom Buttonは、主にモバイルでの用途を想定して考案された、スクリーン下部にポジションを固定されるスクロールによって流れないメインアクションボタンです。`}</p>
    <p>{`存在感が非常に強く、Large Buttonより強く訴求します。Bottom ButtonとLarge Buttonが同画面に両立することはありません。`}</p>
    <h3 {...{
      "id": "icon-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icon-button",
        "aria-label": "icon button permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Icon Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a23b147ba62b6c8301e483ebb2ec4a2a/896c7/button-icon-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.625%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a23b147ba62b6c8301e483ebb2ec4a2a/2aaaf/button-icon-anatomy.webp 160w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/85e47/button-icon-anatomy.webp 320w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/75198/button-icon-anatomy.webp 640w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/691bc/button-icon-anatomy.webp 960w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/223e5/button-icon-anatomy.webp 1280w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/dbbc1/button-icon-anatomy.webp 1938w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a23b147ba62b6c8301e483ebb2ec4a2a/69538/button-icon-anatomy.png 160w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/72799/button-icon-anatomy.png 320w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/6af66/button-icon-anatomy.png 640w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/d9199/button-icon-anatomy.png 960w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/21b4d/button-icon-anatomy.png 1280w", "/static/a23b147ba62b6c8301e483ebb2ec4a2a/896c7/button-icon-anatomy.png 1938w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a23b147ba62b6c8301e483ebb2ec4a2a/6af66/button-icon-anatomy.png",
              "alt": "Icon Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Icon Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Icon Buttonは、アイコン単体でボタンの役割を成すべき時に使用する簡易的なボタンです。テキストラベルが付随しないため、使用にあたってはそのボタンによって起こるアクションが何かをUIとして明瞭にすることが不可欠となります。`}</p>
    <p>{`ユーザーの混乱を誘発しないよう、細心の注意を払いつつ使用してください。`}</p>
    <h3 {...{
      "id": "subtle-button-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#subtle-button-1",
        "aria-label": "subtle button 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Subtle Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dea07208904876bf4bb7961787525cf6/c929c/button-subtle-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "126.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/dea07208904876bf4bb7961787525cf6/2aaaf/button-subtle-anatomy.webp 160w", "/static/dea07208904876bf4bb7961787525cf6/85e47/button-subtle-anatomy.webp 320w", "/static/dea07208904876bf4bb7961787525cf6/75198/button-subtle-anatomy.webp 640w", "/static/dea07208904876bf4bb7961787525cf6/691bc/button-subtle-anatomy.webp 960w", "/static/dea07208904876bf4bb7961787525cf6/520d1/button-subtle-anatomy.webp 1218w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/dea07208904876bf4bb7961787525cf6/69538/button-subtle-anatomy.png 160w", "/static/dea07208904876bf4bb7961787525cf6/72799/button-subtle-anatomy.png 320w", "/static/dea07208904876bf4bb7961787525cf6/6af66/button-subtle-anatomy.png 640w", "/static/dea07208904876bf4bb7961787525cf6/d9199/button-subtle-anatomy.png 960w", "/static/dea07208904876bf4bb7961787525cf6/c929c/button-subtle-anatomy.png 1218w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/dea07208904876bf4bb7961787525cf6/6af66/button-subtle-anatomy.png",
              "alt": "Subtle Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Subtle Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Subtle Buttonは、Large、Medium、Small Buttonと同時に使用します。
ポップアップダイアログを閉じる時など、それほどユーザーの主目的ではないアクションに対して使用します。`}</p>
    <h3 {...{
      "id": "text-link-button-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#text-link-button-1",
        "aria-label": "text link button 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Text Link Button`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/40fa3f8b6386c63a39d29221162b6a04/c929c/button-text-link-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/40fa3f8b6386c63a39d29221162b6a04/2aaaf/button-text-link-anatomy.webp 160w", "/static/40fa3f8b6386c63a39d29221162b6a04/85e47/button-text-link-anatomy.webp 320w", "/static/40fa3f8b6386c63a39d29221162b6a04/75198/button-text-link-anatomy.webp 640w", "/static/40fa3f8b6386c63a39d29221162b6a04/691bc/button-text-link-anatomy.webp 960w", "/static/40fa3f8b6386c63a39d29221162b6a04/520d1/button-text-link-anatomy.webp 1218w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/40fa3f8b6386c63a39d29221162b6a04/69538/button-text-link-anatomy.png 160w", "/static/40fa3f8b6386c63a39d29221162b6a04/72799/button-text-link-anatomy.png 320w", "/static/40fa3f8b6386c63a39d29221162b6a04/6af66/button-text-link-anatomy.png 640w", "/static/40fa3f8b6386c63a39d29221162b6a04/d9199/button-text-link-anatomy.png 960w", "/static/40fa3f8b6386c63a39d29221162b6a04/c929c/button-text-link-anatomy.png 1218w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/40fa3f8b6386c63a39d29221162b6a04/6af66/button-text-link-anatomy.png",
              "alt": "Text Link Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Text Link Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`Text Link Buttonはテキストのみでボタンを表現できるのでレイアウトは非常にコンパクトに収まりますが、主張は少ないので、CTAボタンなどユーザーへの訴求や行動喚起の動作には不向きです。`}</p>
    <p>{`また、Text Link Buttonにはアイコンなどの装飾を施してください。これは、ボタンやリンクとして機能しない通常のテキストと、クリックまたはタップできるテキストを視覚的に判別しやすくするためです。`}</p>
    <p>{`色だけでリンクと伝えると全てのユーザーにリンクであると伝わらない可能性があります。
そのため、文中リンクなど、リンクテキストには原則下線を用います。`}<br />{`
参考：`}<a parentName="p" {...{
        "href": "https://openameba.github.io/a11y-guidelines/1/4/1/"
      }}>{`1.4.1 色だけで伝えない - Ameba Accessibility Guidelines`}</a></p>
    <h3 {...{
      "id": "text-underline-link",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#text-underline-link",
        "aria-label": "text underline link permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Text Underline Link`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/29a8c473f40f310d277570a14a0871f0/c929c/button-text-underline-link-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/29a8c473f40f310d277570a14a0871f0/2aaaf/button-text-underline-link-anatomy.webp 160w", "/static/29a8c473f40f310d277570a14a0871f0/85e47/button-text-underline-link-anatomy.webp 320w", "/static/29a8c473f40f310d277570a14a0871f0/75198/button-text-underline-link-anatomy.webp 640w", "/static/29a8c473f40f310d277570a14a0871f0/691bc/button-text-underline-link-anatomy.webp 960w", "/static/29a8c473f40f310d277570a14a0871f0/520d1/button-text-underline-link-anatomy.webp 1218w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/29a8c473f40f310d277570a14a0871f0/69538/button-text-underline-link-anatomy.png 160w", "/static/29a8c473f40f310d277570a14a0871f0/72799/button-text-underline-link-anatomy.png 320w", "/static/29a8c473f40f310d277570a14a0871f0/6af66/button-text-underline-link-anatomy.png 640w", "/static/29a8c473f40f310d277570a14a0871f0/d9199/button-text-underline-link-anatomy.png 960w", "/static/29a8c473f40f310d277570a14a0871f0/c929c/button-text-underline-link-anatomy.png 1218w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/29a8c473f40f310d277570a14a0871f0/6af66/button-text-underline-link-anatomy.png",
              "alt": "Text Underline Link Buttonのサイズや余白などのデザイン仕様の図解",
              "title": "Text Underline Link Buttonのサイズや余白などのデザイン仕様の図解",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`文中のリンクにて使用します。アイコンが必要でない代わりにアンダーラインを使用してください。
慣習的に下線がなくともリンクであることが分かる場合は、下線が無くても良いものとします。`}</p>
    <h2 {...{
      "id": "レイアウト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88",
        "aria-label": "レイアウト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`レイアウト`}</h2>
    <p>{`ボタンは複数個を組み合わせることで、ユーザーに対して同時に複数の選択肢を提案できます。`}</p>
    <p>{`ただし、ボタンはそれぞれヒエラルキーが定義されており、原則としてヒエラルキーの高いボタンの方が上部に置いてください。`}</p>
    <p>{`また、Neutral Button以外は同じスタイルのボタンを並べることを禁止しているため、ある程度組み合わせのパターンには制約が生じます。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/86604cab21e982c6212c0a794e80dc42/4ee93/button-combination.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVQ4y62S624TMRCF+/7vQhEEJB6CoBCEkCpKq6bNrc1eba/vH/Jsu0lUif7BK6/P2uMz58zsBf9x5Jy5KMAFhxo0mUxrOu4Pa3z09EPPfbWW894qVtWGIVi01WzrHdaPeFPt0NYIqRB+vVsw+/mFg66Zrxa8+/6Rg674drfkcj5j3z/xY7Xk8/wD+2bLr90Vl8sZq/qBq/1v3i8+cf10cyQMKeCiJ6Ykm9ePN2y7PWT4s7sdcYL1/pZ1u/235fIav8YZYmDXP4rFGCMPzYZ26Ejes2421KYtNyUupSSzCEo5HRXaUkOriSmeZTTW0PUdOWWcNbiugZgkvtYNIUVc8NSqwccwEhaF4zNKniaFxNEbJeXAWpxR0qw3uyyyQzwjlO47j9JKskdr0boXPMafWH7Gk2XnHFrrafOFcBgsdd0QYyJYy1BV5BDw3tN2rdS44KZthXSyPEk+sS21LYmMIcQIg8H2Pd6Hty3nGKWLOaWTGkJ0jmA0KQY5i8VaOSurtXJP7j7jyXJ2lqTUtDnV0BjU4SBEp6Mkd6qbhLiukySvLL+Q5RHImjLH7xP1mbPfd4r5C+2F96RbvPldAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/86604cab21e982c6212c0a794e80dc42/2aaaf/button-combination.webp 160w", "/static/86604cab21e982c6212c0a794e80dc42/85e47/button-combination.webp 320w", "/static/86604cab21e982c6212c0a794e80dc42/75198/button-combination.webp 640w", "/static/86604cab21e982c6212c0a794e80dc42/691bc/button-combination.webp 960w", "/static/86604cab21e982c6212c0a794e80dc42/223e5/button-combination.webp 1280w", "/static/86604cab21e982c6212c0a794e80dc42/e9afb/button-combination.webp 2648w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/86604cab21e982c6212c0a794e80dc42/69538/button-combination.png 160w", "/static/86604cab21e982c6212c0a794e80dc42/72799/button-combination.png 320w", "/static/86604cab21e982c6212c0a794e80dc42/6af66/button-combination.png 640w", "/static/86604cab21e982c6212c0a794e80dc42/d9199/button-combination.png 960w", "/static/86604cab21e982c6212c0a794e80dc42/21b4d/button-combination.png 1280w", "/static/86604cab21e982c6212c0a794e80dc42/4ee93/button-combination.png 2648w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/86604cab21e982c6212c0a794e80dc42/6af66/button-combination.png",
              "alt": "ボタンの種類ごとの組み合わせの例を並べた一覧表",
              "title": "ボタンの種類ごとの組み合わせの例を並べた一覧表",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "レイアウト例",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88%E4%BE%8B",
        "aria-label": "レイアウト例 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`レイアウト例`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/43dee14580ad72f4099ad55f3bc5d7bd/58354/button-combination-layout.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnUlEQVQoz42SbW8SQRSF99frJ4kmtiaSRos1aBMxtrERldioQASLL1CjlhK0NIUtdssCO8vMzjxmFru8tB88k5vJnJk5c8+94xhjUDpCKslESVSkiHSE5S200SilYl5rnfB2jqIojnnO4QIGTGhmS2Oww2IiJGEwWdizEEGIGIsF3jk+P+HW7h3SpXXWK1lSb1Z43sgnh8qtCjcKt1l5m2at9AApZcz/6B3wsLrJRuUx1XZtJng67JOrbfG0tsXmx1wcxZ/lRLA3cKkf7VPv7HPQbaL+CQ4Cn8Nei5bbZhgMr7C8BG0tG3Pl3jxva+gO+/hiKuoorej6LqNwRLn5gWuvUmw3XiQXhRScjT3a7q+4NNm9J9P6yZCB8Gn2DslUH/HluDEVnH/Z2tg7+sxvr5MI2i7LSCKU4HTUxwvOZ13FXMrc+TM+I/d1m2f1HfLfC+x8e8mnTj059L5Z5no+xc3CKmvFDBcaC99HR/HDcYYnXpe77+5xv7hBppRldTdNvvE6EQyttdEAz/fi4utIz77VUlyyvJz+/zRlGX8Bm3nzwVAJTqQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/43dee14580ad72f4099ad55f3bc5d7bd/2aaaf/button-combination-layout.webp 160w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/85e47/button-combination-layout.webp 320w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/75198/button-combination-layout.webp 640w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/691bc/button-combination-layout.webp 960w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/223e5/button-combination-layout.webp 1280w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/2126c/button-combination-layout.webp 1396w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/43dee14580ad72f4099ad55f3bc5d7bd/69538/button-combination-layout.png 160w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/72799/button-combination-layout.png 320w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/6af66/button-combination-layout.png 640w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/d9199/button-combination-layout.png 960w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/21b4d/button-combination-layout.png 1280w", "/static/43dee14580ad72f4099ad55f3bc5d7bd/58354/button-combination-layout.png 1396w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/43dee14580ad72f4099ad55f3bc5d7bd/6af66/button-combination-layout.png",
              "alt": "ボタンのレイアウト例",
              "title": "ボタンのレイアウト例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`上記はあくまで参考値であり、レイアウトの都合により微調整するのは可能とします。`}</p>
    <h2 {...{
      "id": "状態",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%8A%B6%E6%85%8B",
        "aria-label": "状態 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`状態`}</h2>
    <p>{`ボタンはシステムの状態やユーザーの入力に応じて、一時的にスタイルを変えるときはあります。
初期状態では、基本的にenabledの状態がユーザーに見えていることが望ましいです。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/de89ebb951639a5844b09a391501e5ee/18a69/button-state-chart.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/de89ebb951639a5844b09a391501e5ee/2aaaf/button-state-chart.webp 160w", "/static/de89ebb951639a5844b09a391501e5ee/85e47/button-state-chart.webp 320w", "/static/de89ebb951639a5844b09a391501e5ee/75198/button-state-chart.webp 640w", "/static/de89ebb951639a5844b09a391501e5ee/691bc/button-state-chart.webp 960w", "/static/de89ebb951639a5844b09a391501e5ee/223e5/button-state-chart.webp 1280w", "/static/de89ebb951639a5844b09a391501e5ee/22983/button-state-chart.webp 4820w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/de89ebb951639a5844b09a391501e5ee/69538/button-state-chart.png 160w", "/static/de89ebb951639a5844b09a391501e5ee/72799/button-state-chart.png 320w", "/static/de89ebb951639a5844b09a391501e5ee/6af66/button-state-chart.png 640w", "/static/de89ebb951639a5844b09a391501e5ee/d9199/button-state-chart.png 960w", "/static/de89ebb951639a5844b09a391501e5ee/21b4d/button-state-chart.png 1280w", "/static/de89ebb951639a5844b09a391501e5ee/18a69/button-state-chart.png 4820w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/de89ebb951639a5844b09a391501e5ee/6af66/button-state-chart.png",
              "alt": "各ボタンの状態別表示の一覧",
              "title": "各ボタンの状態別表示の一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "アクセシビリティ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3",
        "aria-label": "アクセシビリティ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`アクセシビリティ`}</h2>
    <h3 {...{
      "id": "操作",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%93%8D%E4%BD%9C",
        "aria-label": "操作 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`操作`}</h3>
    <details>
      <summary>タップやクリック可能な領域を44px × 44px以上確保する</summary>
      <p>{`タップやクリック可能な領域が小さいと、誤タップや押しづらさにつながります。Icon ButtonのMedium以下のサイズなど、ボタンのサイズが小さい場合はタップ領域と余白を含めて44pxでも構いません。`}</p>
      <p>{`段落や文章の中のLink Buttonは例外として扱って構いません。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/2/5/5/"
        }}>{`2.5.5 ターゲットのサイズを理解する - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>状態を表示する</summary>
      <p>{`ボタンはシステムの状態やユーザーの入力に応じて、一時的に姿を変える時があります。それぞれのStateに合わせた表示をしましょう。`}</p>
      <p>{`またStateに応じてコンポーネントにマシンリーダブルな状態を付与しましょう（ex: `}<a parentName="p" {...{
          "href": "https://momdo.github.io/wai-aria-1.2/#introstates"
        }}>{`WAI-ARIA`}</a>{` / `}<a parentName="p" {...{
          "href": "https://developer.apple.com/documentation/objectivec/nsobject/1615202-accessibilitytraits"
        }}>{`Accessibility Traits`}</a></p>
    </details>
    <details>
      <summary>フォーカススタイルを見えるようにする</summary>
      <p>{`キーボードで操作する場合、フォーカス時のスタイルが見えないと、何をこれから実行するのかがわからなくなってしまいます。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/2/4/7/"
        }}>{`2.4.7 フォーカスを見えるようにする - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "色とコントラスト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%89%B2%E3%81%A8%E3%82%B3%E3%83%B3%E3%83%88%E3%83%A9%E3%82%B9%E3%83%88",
        "aria-label": "色とコントラスト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`色とコントラスト`}</h3>
    <details>
      <summary>色だけで伝えない</summary>
      <p>{`それぞれのStateを色の違いだけで表現すると、視覚的に閲覧していないユーザーや環境によってはStateの違いがわからなくなってしまいます。色以外の、borderやweightの太さで変化をつけるようにしましょう。`}</p>
      <p><a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/4/1/"
        }}>{`参考: 1.4.1 色だけで伝えない - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>テキスト色、アイコン色と背景色のコントラスト比を高くする</summary>
      <p>{`テキスト、アイコンの視認性を高めるため、背景色に対してそれぞれ下記のコントラスト比を担保しましょう。`}</p>
      <ul>
        <li parentName="ul">{`テキスト、画像中のテキストは4.5:1以上`}</li>
        <li parentName="ul">{`アイコン色は3:1以上`}</li>
      </ul>
      <p>{`SpindleのSurface ColorとText Color・Object Colorの組み合わせを適切に利用することで、コントラスト比が十分に担保されます。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/4/3/"
        }}>{`1.4.3 テキストや文字画像のコントラストを確保する - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "レイアウト-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88-1",
        "aria-label": "レイアウト 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`レイアウト`}</h3>
    <details>
      <summary>テキスト・アイコンが拡大縮小できる</summary>
      <p>{`ボタンのテキストやアイコンサイズは、200％まで拡大できるようにします。`}</p>
      <p>{`ロービジョンのユーザーは、小さなテキストを読むことが困難です。そのため、テキストサイズを大きく設定していたり、一時的に画面をズームして表示したりすることがあります。拡大縮小した際に、テキストラベルが見切れて表示され、読めないことのないように設計しましょう。また、拡大してもアイコンだけが小さいままだと、ボタンを見逃してしまうかもしれません。`}</p>
      <p>{`テキストサイズ拡大に伴い、改行を余儀なくされる際に、テキストが3行にならないよう、レイアウト自体を調整する。`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/45e2c2544577df17784e7a42e124d6b3/4a136/button-textsize.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "41.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/45e2c2544577df17784e7a42e124d6b3/2aaaf/button-textsize.webp 160w", "/static/45e2c2544577df17784e7a42e124d6b3/85e47/button-textsize.webp 320w", "/static/45e2c2544577df17784e7a42e124d6b3/75198/button-textsize.webp 640w", "/static/45e2c2544577df17784e7a42e124d6b3/691bc/button-textsize.webp 960w", "/static/45e2c2544577df17784e7a42e124d6b3/223e5/button-textsize.webp 1280w", "/static/45e2c2544577df17784e7a42e124d6b3/93b17/button-textsize.webp 2825w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/45e2c2544577df17784e7a42e124d6b3/69538/button-textsize.png 160w", "/static/45e2c2544577df17784e7a42e124d6b3/72799/button-textsize.png 320w", "/static/45e2c2544577df17784e7a42e124d6b3/6af66/button-textsize.png 640w", "/static/45e2c2544577df17784e7a42e124d6b3/d9199/button-textsize.png 960w", "/static/45e2c2544577df17784e7a42e124d6b3/21b4d/button-textsize.png 1280w", "/static/45e2c2544577df17784e7a42e124d6b3/4a136/button-textsize.png 2825w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/45e2c2544577df17784e7a42e124d6b3/6af66/button-textsize.png",
                "alt": "ボタンにテキストサイズの拡大縮小に耐えうる設計の解説イメージ",
                "title": "ボタンにテキストサイズの拡大縮小に耐えうる設計の解説イメージ",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/4/4/"
        }}>{`1.4.4 テキストサイズを拡大縮小できる - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "ライティング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A9%E3%82%A4%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0",
        "aria-label": "ライティング permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ライティング`}</h3>
    <details>
      <summary>何が起きるか、リンク先の内容が予測できるラベルをつける</summary>
      <p>{`ボタン、リンクボタンのラベルは、操作やリンク先の内容が予測できるように明確にしましょう。押すと何が起きるのかが曖昧だと、ユーザーが怯えて押せなくなってしまうかもしれません。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/2/4/4/"
        }}>{`2.4.4 リンクの目的を理解できるようにする - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "実装",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AE%9F%E8%A3%85",
        "aria-label": "実装 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`実装`}</h3>
    <details>
      <summary>プラットフォーム標準の方法で実装している or 支援技術で操作ができる</summary>
      <p>{`プラットフォームごとに標準の要素でボタンを実装します。標準的な実装であれば、キーボードやスクリーンリーダーなどの支援技術でも操作できます。`}</p>
      <p>{`もしカスタムしたボタンを使う場合には、エミュレータでキー操作ができること、支援技術（VoiceOver, Talkbackなど）で動作を確認しましょう。また、押せない（disabledな）ボタンが、キー操作でも操作できなくなることを確認しましょう。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/2/1/1/"
        }}>{`2.1.1 キーボード、タッチデバイスで操作できる - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>アイコンボタンには代替テキストを設定する</summary>
      <p>{`テキストラベルがないと、スクリーンリーダーで「ボタン」と読み上げられ、何のボタンなのかわからなくなってしまうことがあります。アイコンだけのボタンには、代替テキストを設定しましょう。`}</p>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      